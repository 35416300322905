import React, { useRef } from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import Layout from '../../layout';
import SEO from '../../components/Seo/Seo';
import { FormWrapper } from '../../components/Form/Form.style';

const Lidworden = () => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 300, callbackName: 'initMap' });

  const ref = useRef();

  useOnclickOutside(ref, () => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = e => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log('📍 Coordinates: ', { lat, lng });
      })
      .catch(error => {
        console.log('😱 Error: ', error);
      });
  };
  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={id}
          className="geosuggest__item"
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <Layout>
      <SEO title="Lid Worden" />
      <FormWrapper className="Lid-worden">
        <iframe
          title="Lid worden video"
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/XT_nmyCaOMw?rel=0&amp;controls=0&amp;showinfo=0"
          frameBorder="0"
          allowFullScreen
        />
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          action="/lidworden-bedankt"
        >
          <input type="hidden" name="form-name" value="contact" />

          <label className="fieldgroup" htmlFor="voorletters">
            Voorletters <span>*</span>
            <input type="text" name="voorletters" required />
          </label>

          <label className="fieldgroup" htmlFor="tussenvoegsel">
            Tussenvoegsel
            <input type="text" name="tussenvoegsel" />
          </label>

          <label className="fieldgroup" htmlFor="achternaam">
            Achternaam <span>*</span>
            <input type="text" name="achternaam" required />
          </label>

          <label className="fieldgroup" htmlFor="rang">
            Rang <span>*</span>
            <input type="text" name="rang" required />
          </label>

          <label className="fieldgroup" htmlFor="krijgsmachtonderdeel">
            Krijgsmachtonderdeel <span>*</span>
            <select name="koninklijke[]" required>
              <option value="koninklijke_landmacht">
                Koninklijke Landmacht
              </option>
              <option value="koninklijke_luchtmacht">
                Koninklijke Luchtmacht
              </option>
              <option value="koninklijke_marechaussee">
                Koninklijke Marechaussee
              </option>
              <option value="commando_diensten_centra">
                Commando Diensten Centra
              </option>
              <option value="koninklijke_marine">Koninklijke Marine</option>
            </select>
          </label>

          <label className="fieldgroup" htmlFor="wapen/dienstvak">
            Wapen/dienstvak <span>*</span>
            <input type="text" name="wapen/dienstvak" required />
          </label>

          {/* <div ref={ref}>
            <label className="fieldgroup" htmlFor="adres">
              Adres <span>*</span>
              <input
                value={value}
                onChange={handleInput}
                disabled={!ready}
                name="adres"
                placeholder="Adres"
                required
              /> */}
          {/* We can use the "status" to decide whether we should display the dropdown or not */}
          {/* {status === 'OK' && (
                <div className="geosuggest__suggests-wrapper">
                  <ul>{renderSuggestions()}</ul>
                </div>
              )} */}
          {/* </label>
          </div> */}

          <label className="fieldgroup" htmlFor="street">
            Straat <span>*</span>
            <input type="text" name="street" required />
          </label>

          <label className="fieldgroup" htmlFor="city">
            Woonplaats <span>*</span>
            <input type="text" name="city" required />
          </label>

          <label className="fieldgroup" htmlFor="postCode">
            Postcode <span>*</span>
            <input type="text" name="postcode" placeholder="0000 AA" required />
          </label>

          <label className="fieldgroup" htmlFor="geboortedatum">
            Geboortedatum <span>*</span>
            <input
              type="date"
              name="geboortedatum"
              placeholder="09/05/1994"
              required
            />
          </label>

          <label className="fieldgroup" htmlFor="registratienummer">
            Registratienummer <span>*</span>
            <input type="text" name="registratienummer" required />
          </label>

          <label className="fieldgroup" htmlFor="werknemernummer">
            Werknemernummer <span>*</span>
            <small style={{ marginTop: 10, fontSize: 14, width: '100%' }}>
              9 cijfers, bestaand uit het peoplesoftnummer met cijfers ervoor en
              erachter (zie uw weddestrook)
            </small>
            <input type="text" name="werknemernummer" required />
          </label>

          <label className="fieldgroup" htmlFor="telefoonnummer">
            Telefoonnummer <span>*</span>
            <input type="tel" name="telefoonnummer" required />
          </label>

          <label className="fieldgroup" htmlFor="email">
            Email <span>*</span>
            <input type="email" name="email" required />
          </label>

          <label className="fieldgroup" htmlFor="contributiebetaling">
            Contributiebetaling
            <select name="contributiebetaling[]">
              <option value="nsk">Actief dienenden via NSK</option>
              <option value="abp">Postactieven via ABP</option>
              <option value="incasso">
                Reserve-officieren via automatische incasso
              </option>
            </select>
          </label>

          <label className="fieldgroup" htmlFor="IBAN_nummer">
            IBAN nummer
            <input type="text" name="IBAN_nummer" />
          </label>

          <label className="fieldgroup" htmlFor="opmerkingen">
            Opmerkingen
            <textarea name="opmerkingen" cols="30" rows="10" />
          </label>

          <div className="fieldgroup">
            <h4>Uw gegevens</h4>
            <div>
              <p>
                Uw gegevens worden alleen gebruikt voor registratiedoeleinden en
                de verzending van de nieuwsbrief. Ze zullen nooit aan derden
                worden doorgegeven zonder uw toestemming.
              </p>
            </div>
          </div>

          <div className="fieldgroup">
            <h4>De Financiële Dienstverleners</h4>
            <div>
              <p>
                DFD is de verzekeringspartner van de NOV en de NOV-leden. Met
                persoonlijk advies en maatwerkproducten ontzorgt DFD de militair
                en gezinsleden. Mag DFD vrijblijvend contact met u opnemen om te
                bezien of zij iets voor u kunnen betekenen?
              </p>
            </div>
          </div>

          <label className="fieldgroup" htmlFor="DFD">
            DFD
            <select name="DFD[]">
              <option value="Nee">
                Ik wens nu geen gebruik te maken van het DFD aanbod voor een
                adviesgesprek
              </option>
              <option value="Ja">
                DFD mag mij benaderen voor een persoonlijk en vrijblijvend
                adviesgesprek
              </option>
            </select>
          </label>

          <button className="button primary" type="submit">
            Registreer
          </button>
        </form>
      </FormWrapper>
    </Layout>
  );
};

export default Lidworden;
