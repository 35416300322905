import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const FormWrapper = styled.div`
  iframe {
    width: 100%;

    @media print {
      display: none;
    }
  }

  form {
    color: ${themeGet('color.2')};
    display: flex;
    flex-flow: column wrap;
    width: 90%;
    max-width: 700px;
    margin: auto;
    padding-top: 3rem;

    button[type='submit'] {
      font-size: 1rem;
      margin: 25px 0;
    }

    h2 {
      font-size: 3rem;
      margin-bottom: 1em;
      /* font-family: var(--font-primary); */
    }

    .fieldgroup {
      flex: 100% 1;
      display: flex;
      flex-flow: row wrap;
      margin: 1rem 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);

      @media print {
        margin: 0;
      }

      &.fieldgroup--checkbox,
      &.fieldgroup--radio {
        /* font-family: var(--font-secondary); */
        font-size: 1rem;
        display: flex;
        flex-flow: row wrap;

        input {
          width: auto;
          margin: 1% 5% 0 0;
          flex: 0 0 100%;
        }

        span {
          flex: calc(100% - 35px) 0 1;
        }

        p {
          flex: 100% 0 1;
        }

        .radio-item {
          margin: 2% 0;

          &:last-child {
            margin-bottom: 10%;
          }
        }
      }

      .react-datepicker-wrapper,
      .react-datepicker__input-container {
        width: 100%;
      }

      select {
        height: 50px;
      }

      p {
        /* font-family: var(--font-secondary); */
        color: ${themeGet('color.2')};
        opacity: 0.8;
        line-height: 1.5em;
      }

      input,
      select,
      textarea {
        background: ${themeGet('color.7')};
        /* font-family: var(--font-secondary); */
        color: ${themeGet('color.2')};
        margin-top: 0.7em;
        border: none;
        padding: 1em;
        font-size: 1rem;
        outline: none;
        border-radius: 5px;
        opacity: 0.6;
        transition: 0.3s ease-in;
        width: 100%;
        box-sizing: border-box;

        @media print {
          border: 1px solid #000;
        }

        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: ${themeGet('color.2')};
        }
        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: ${themeGet('color.2')};
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: ${themeGet('color.2')};
          opacity: 1;
        }
        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: ${themeGet('color.2')};
        }
        &:-ms-input-placeholder {
          /* Microsoft Edge */
          color: ${themeGet('color.2')};
        }

        &:focus {
          opacity: 1;
        }
      }

      textarea {
        resize: none;
      }

      .geosuggest__suggests-wrapper {
        width: 100%;
      }

      .geosuggest__suggests-wrapper > ul {
        padding: 0;
        margin: 0;
        list-style: none;

        > li.geosuggest__item {
          background: ${themeGet('color.7')};
          padding: 1em;
          margin: 4px 0;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
`;
